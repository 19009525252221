import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { PageLayout } from "src/components/layout/PageLayout";
import { Tour } from "src/components/Tour";
import { breakpoint } from "src/utils/media-query";
import { Text } from "src/components/Text";
import { Carousel } from "src/components/Carousel";
import { DesktopOnly } from "src/components/responsive";

interface BookPageProps extends PageProps {
  data: GatsbyTypes.BookPageQuery;
}

const BookPage: React.FC<BookPageProps> = ({ data }) => {
  const tours = [ ...data.allStrapiTour.nodes ].sort(
    (a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const sortByPrice = a.price! < b.price! ? -1 : 1;

      if (a.featured && !b.featured) {
        return -1;
      }

      if (!a.featured && b.featured) {
        return 1;
      }

      return sortByPrice;
    }
  );

  const featuredTours = tours.filter(tour => tour.featured);
  const standardTours = tours.filter(tour => !tour.featured);

  return (
    <PageLayout title="Book" width="full">
      <DeviceOnlyCarouselWrapper>
        <Carousel
          slides={tours.map(tour => (
            <Tour key={tour.id} {...tour} />
          ))}
          intrinsicHeight
          topControls
        />
      </DeviceOnlyCarouselWrapper>
      <DesktopOnly style={{ flexDirection: "column" }}>
        <TitleWrapper>
          <Text as="h1" uppercase>
            Book your tour
          </Text>
        </TitleWrapper>
        {featuredTours.length > 0 && (
          <FeatureWrapper>
            <ToursWrapper>
              {featuredTours.map(tour => (
                <Tour key={tour.id} {...tour} />
              ))}
            </ToursWrapper>
          </FeatureWrapper>
        )}
        <ToursWrapper $top={featuredTours.length == 0}>
          {standardTours.map(tour => (
            <Tour key={tour.id} {...tour} />
          ))}
        </ToursWrapper>
      </DesktopOnly>
    </PageLayout>
  );
};

export default BookPage;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  ${breakpoint("tabletLL")} {
    padding-top: 3rem;
  }
`;

const DeviceOnlyCarouselWrapper = styled.div`
  .carousel__slider {
    max-width: 100%;
  }
  img {
    object-fit: cover!important;
  }
  margin-bottom: 3rem;
  
  ${breakpoint("tabletLL")} {
    display: none;
  }
`;

const ToursWrapper = styled.div<{ $top?: boolean }>`
  display: none;
  
  ${breakpoint("tabletLL")} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ${({ $top = true }) => $top ? "1rem" : 0};
    margin-bottom: 3rem;
  }
`;

const FeatureWrapper = styled.div`
  flex-basis: 100%;

  ${ToursWrapper} {
    margin-bottom: 0rem;
  }
`;

export const query = graphql`
  query BookPage {
    allStrapiTour {
      nodes {
        id
        title
        subtitle
        notice
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        price
        booking_url
        featured
      }
    }
  }
`;