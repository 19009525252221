import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import slugify from "slugify";
import { navigate } from "gatsby";

import { Text } from "src/components/Text";
import { BodyText } from "src/components/BodyText";
import { getImageMetadata } from "src/utils/image";
import { breakpoint } from "src/utils/media-query";

import { Button } from "./Button";

type TourProps = Pick<
  GatsbyTypes.StrapiTour,
  "id" | "title" | "subtitle" | "notice" | "description" | "price" | "booking_url"
> & {
  readonly image: GatsbyTypes.Maybe<{
    readonly localFile: GatsbyTypes.Maybe<{
      readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, "gatsbyImageData">>
    }>
  }>
};

export const Tour: React.FC<TourProps> = ({
  title,
  subtitle,
  notice,
  description,
  image,
  price,
  booking_url: bookingUrl
}) => {
  const [ tourImage, tourImageAlt ] = getImageMetadata(image);

  return (
    <TourCard>
      {tourImage && (
        <ImageAspectWrapper>
          <TourImage image={tourImage} alt={tourImageAlt} />
        </ImageAspectWrapper>
      )}
      <TextWrapper>
        <TitleSection>
          <Text as="h2" color="red">
            {title?.split(".").map(titleSegment => (
              <Text key={titleSegment} as="h2" color="red">
                {titleSegment}
              </Text>
            ))}
          </Text>
          <PriceText>
            {`£${price}`}
          </PriceText>
        </TitleSection>
        {subtitle && (
          <Text as="h3">
            {subtitle.split(".").map(subtitleSegment => (
              <Text key={subtitleSegment} as="h3">
                {subtitleSegment}
              </Text>
            ))}
          </Text>
        )}
        {notice && (
          <NoticeWrapper>
            <Text as="p" color="red">
              {notice}
            </Text>
          </NoticeWrapper>
        )}
        {description && (
          <DescriptionWrapper>
            <ul>
              <BodyText text={description} indented />
            </ul>
          </DescriptionWrapper>
        )}
        {bookingUrl && (
          <BookButton
            onClick={() => navigate(`/book/${slugify(title || "", {
              lower: true,
              strict: true
            })}`)}
          >
            <Text as="small" uppercase>
              Book
            </Text>
          </BookButton>
        )}
      </TextWrapper>
    </TourCard>
  );
};

const TourCard = styled.div`
  flex-basis: 27rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: stretch;
  align-self: flex-start;
  
  ${breakpoint("tabletLL")} {
    flex-basis: 25.5rem;
    align-self: stretch;
    margin: 1.5rem;
  }
  ${breakpoint("desktopL")} {
    flex-basis: 27rem;
    margin: 2rem;
  }
`;

const ImageAspectWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 62.5%;

  .gatsby-image-wrapper {
    position: absolute!important;
  }
`;

const TourImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: contain;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  
  ${breakpoint("tabletLL")} {
    flex: 1;
    padding: 2rem;
    padding-top: 1.5rem;
  }
`;

const NoticeWrapper = styled.div`
  margin-top: 1.2rem;
`;

const DescriptionWrapper = styled.div`
  margin-top: 1.4rem;
  flex: 1;

  ul {
    list-style-type: initial;
  }

  li {
    margin-left: 2rem!important;
    text-indent: 0!important;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  h2 {
    line-height: 1.8rem;
  }
  margin-bottom: 0.5rem;
`;

const PriceText = styled(Text).attrs({
  as: "h2",
  color: "red",
  uppercase: true
})``;

const BookButton = styled(Button)`
  align-self: center;
  margin-top: 2rem;
`;